import { createRouter, createWebHistory } from 'vue-router';
import VueBodyClass from 'vue-body-class';

var connexion = () => import('../views/Connexion.vue');
var accueil = () => import('../views/Accueil.vue');

const routes = [
  // TODO : mettre un path sur /connexion
  {
    path: '/',
    name: 'Connexion',
    meta: { bodyClass: 'connexion' },
    component: connexion,
  },
  {
    path: '/accueil',
    name: 'Accueil',
    meta: { bodyClass: 'accueil' },
    component: accueil,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const vueBodyClass = new VueBodyClass(routes);
router.beforeEach((to, from, next) => {
  var token = localStorage.getItem('AVOLOI_MEDIATION_AUTH_TOKEN');
  if (to.name == 'Connexion' && token) {
    next({ name: 'Accueil' });
    return;
  } else if (to.name == 'Accueil' && !token) {
    next({ name: 'Connexion' });
    return;
  }
  vueBodyClass.guard(to, next);
});

export default router;
