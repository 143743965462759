<template>
  <router-view />
</template>

<script>
import moment from 'moment';
export default {
  data() {
    return {
      loaded: false,
      sessionExpired: false,
    };
  },
  async mounted() {
    this.verifyToken(true);
  },
  methods: {
    // Permet de vérifier la validité du token
    async verifyToken(alreadyLogged) {
      var token = await localStorage.getItem('AVOLOI_MEDIATION_AUTH_TOKEN');
      if (token) {
        var base64 = token.split('.')[1];
        var jwtPayload = JSON.parse(decodeURIComponent(atob(base64)));
      }
      if (token && moment().diff(moment.unix(jwtPayload.exp), 'minutes', true) < 0) {
        if (alreadyLogged) this.$axiosConfigInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        if (token) {
          return moment().diff(moment.unix(jwtPayload.exp), 'milliseconds', true);
        }
      } else {
        if (token) return true;
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@/assets/bootstrap/bootstrap-grid.min.css';
@import '~@/assets/scss/main.scss';
@import '~@/assets/fonts/icon.css';
</style>
